import React, { useEffect, useState, useRef } from "react";
import { MainContent, PrincipalContainer } from "../Styles/Main/MainContentStyle";
import { EnrollmentSDK } from "@soyyo/sdk_web_enrollment";
import { TramitesSDK } from "@soyyo/sdk_web_transactions";
import { AuthenticationSDK, configSoyYo } from "@soyyo/sdk_web_authentication";
import { useMachineContext } from "../Context/contextMachine";
import { processType, acceptTyCExternal, channel, redirectionUrlHomeBank, proccessIdMock, sessionTokenMock, sdkEnrollmentFace, sdkEnrollmentDocument, sdkAuthenticate, sdkAuthorization, errorCodes, sourceId, minutesConverter, codesNoSDK } from "../Constants/Constants"
import Spinner from "./Spinner/Spinner"
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { ContainerRecaptcha } from "../Styles/ContainerRecaptcha"
import { LogoBank } from "../Styles/LogoBank"
import Footer from "./Footer/Footer";
import config from "../config"
import { getInternalCode, getErrorModalText } from '../Utilities/functions'
import { HandlerError } from '../HandlerComponents/HandlerError'
import { HandlerFlow } from '../HandlerComponents/HandlerFlow'
import { saveLogs } from '../Services/SaveLogs'

export function MainBoard() {
  const [machine, send] = useMachineContext();
  const [showAlertCancel, setShowAlertCancel] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [testMode, setTestMode] = useState(false);
  const [maxDocument, setMaxDocument] = useState(0);
  const [minDocument, setMinDocument] = useState(0);
  const [maxAttempts, setMaxAttempts] = useState(0);
  const [cancelType, setCancelType] = useState(0);
  const [timeOut, setTimeOut] = useState(0);
  const isMatchSpinner = ["validateCookie","validateParameters", "recaptcha","getBfKey","generateBfKey","validateClientSoyYo","decryptData","checkUserState","checkTermsAndConditions","saveUserTries","activateUser","validateTransaction","authenticateUser","enrollmentUser","authorizeTransaction","success","refreshKeyTransaction","refreshKeyActivateUser","refreshKeyRetries"];
  const [titleError, setTitleError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [iconType, setIconType] = useState("");
  const [secondaryMessageError, setSecondaryMessageError] = useState("");
  const [buttonBack, setButtonBack] = useState(false);
  const recaptchaRef = useRef();
  let enrollment;
  let authentication;
  let transactions;

  useEffect(() => {
    config.sdk.PRODUCTION = config.sdk.PRODUCTION === "true"
    setTimeOut(parseInt(config.TIME_OUT_REDIRECT)*minutesConverter)
    if(config.TEST_MODE === "true"){
      setTestMode(true);
      setMinDocument(parseInt(config.TEST_DOCUMENT_MIN))
      setMaxDocument(parseInt(config.TEST_DOCUMENT_MAX))
      setMaxAttempts(parseInt(config.TEST_MAX_ATTEMPS))
    }
  },[]);

  /*-------RECAPTCHA----------*/

  const getRecaptchaToken = async () => {
    const token = await recaptchaRef.current.executeAsync();
    send("SETRECAPTCHAVALUE",{recaptcha: token});
  }

  const setRecaptchaToken = (value) => {
    const token = value;
    send("SETRECAPTCHAVALUE",{recaptcha: token});
  }

  /*-------SDK ENROLAMIENTO----------*/

  const enrollmentSdk = async () => {
    enrollment = !testMode ? new EnrollmentSDK(config.sdk, 'body', true, true) : null;
    const body = await getBasicDataBody();
    await configSoyYo(config.sdk, 'body')
    basicDataRegister(body)
  }

  const getBasicDataBody = async () => {
    return {
      entityId: config.sdk.ENTITY_ID,
      processType: processType,
      documentType: machine.context.documentTypeEq,
      identificationNumber: machine.context.documentNumber,
      phoneIndicative: machine.context.phoneIndicative,
      phoneNumber: machine.context.phone,
      channel: channel,
      email: machine.context.email,
      appIdentifier: config.sdk.APP_IDENTIFIER,
      acceptTyCExternal: acceptTyCExternal
    }
  }

  const basicDataRegister = async data =>{
    if (testMode) {
      handleTestModeBasicDataRegister(data);
      return;
    }
    saveSdkAction('initBasicData');
    enrollment.basicDataRegister(data,
      async success => {
        data.processId = success.data.processId;
        machine.context.processId = data.processId
        const body = await getCaptureFaceBody(data);
        saveLogs(machine.context,sourceId.basicDataRegister,sdkEnrollmentFace,success)
        captureFaceSdk(body)
      },
      error => {
        saveLogs(machine.context,sourceId.basicDataRegister,sdkEnrollmentFace,error,error?.data?.faceData)
        if(error.code === "EP005") {
          const faceData = error.data.faceData || 'DEFAULT';
          const errorMessage = getErrorModalText(faceData);
          setTitleError(errorMessage.title);
          setMessageError(errorMessage.message);
          setIconType(errorMessage.iconType);
          setButtonBack(errorMessage.buttonBack)
          setSecondaryMessageError(errorMessage.secondaryMessage)
          send("CAMERAERROR");
        } else {
          const bioCode = getInternalCode(error.code, sdkEnrollmentFace)
          const errorMessage = {code: bioCode.code, message: error.message}
          send("REDIRECTERROR",errorMessage);
        }
      }
    );
  }

  const getCaptureFaceBody = async data => {
    return {
      processId: parseInt(data.processId),
      processType: data.processType,
      channel: data.channel,
      entityId: data.entityId,
      identificationNumber: machine.context.documentNumber,
    }
  };

  const handleTestModeBasicDataRegister = async data => {
    data.processId = proccessIdMock
    machine.context.processId = proccessIdMock
    const body = await getCaptureFaceBody(data);
    captureFaceSdk(body)
  };

  const captureFaceSdk = async captureFaceData => {
    if (testMode) {
      handleTestMode("ACTIVATEUSER");
      return;
    }
    saveSdkAction('initCapureFace');
    enrollment.captureFace(captureFaceData, 
      success => {
        saveLogs(machine.context,sourceId.captureFace,sdkEnrollmentFace,success.liveness)
        switch (success.liveness.code) {
          case "EP006":
            documentValidateSDK(captureFaceData)
            break;
          case "EP004":
            send("ACTIVATEUSER");
            break;
          default: {
            const bioCode = getInternalCode(success.liveness.code, sdkEnrollmentFace)
            error = {code: bioCode.code}
            send("REDIRECTERROR", error);
          }
        }
    },
    error => {
      saveLogs(machine.context,sourceId.captureFace,sdkEnrollmentFace,error,error?.data?.faceData)
      switch (error.code) {
        case "EP007":
          send("SAVETRIES");
          break;
        case "EP005": {
          const faceData = error.data.faceData || 'DEFAULT';
          const errorMessage = getErrorModalText(faceData);
          setTitleError(errorMessage.title);
          setMessageError(errorMessage.message);
          setIconType(errorMessage.iconType);
          setButtonBack(errorMessage.buttonBack)
          setSecondaryMessageError(errorMessage.secondaryMessage)
          send("CAMERAERROR");
          break;
          }
        case "EP003":
          send("SAVETRIESANDOUT");
          break;
        default: {
          const bioCode = getInternalCode(error.code, sdkEnrollmentFace)
          send("REDIRECTERROR", bioCode)
        }
      }
    },
    handleClose);
  }

  const documentValidateSDK = async documentValidateData => {
    saveSdkAction('initDocumentValidate');
    enrollment.documentValidate(documentValidateData,
      _success => {
        saveLogs(machine.context,sourceId.documentValidate,sdkEnrollmentDocument,_success)
        send("ACTIVATEUSER");
      },
      error => {
        saveLogs(machine.context,sourceId.documentValidate,sdkEnrollmentDocument,error)
        switch (error.code) {
          case "EP007":
            send("SAVETRIES");
            break;
          case "EP008":
            send("SAVETRIES");
            break;
          default: {
            const bioCode = getInternalCode(error.code, sdkEnrollmentDocument)
            error = {code: bioCode.code, message: error.message}
            send("REDIRECTERROR", error);
          }
        }
      },
      handleClose);
  }

  /*-------SDK AUTENTICACIÓN----------*/

  const authenticateSDK = async () => {
    authentication = !testMode ? new AuthenticationSDK(config.sdk, 'body', true, true) : null;
    await configSoyYo(config.sdk, 'body')
    authenticateUser(await getAuthenticationDataBody())
  }

  const getAuthenticationDataBody = async () => {
    return {
      documentType: machine.context.documentTypeEq,
      identificationNumber: machine.context.documentNumber,
      appIdentifier: config.sdk.APP_IDENTIFIER,
      channel: channel,
      entityId: config.sdk.ENTITY_ID
    }
  }

  const authenticateUser = async data => {
    if (testMode) {
      handleTestMode("AUTHENTICATED");
      return;
    }
    saveSdkAction('initAuthenticate');
    authentication.authenticate(data,
      success => {
        saveLogs(machine.context,sourceId.authenticate,sdkAuthenticate,success)
        if (success.code === "AP001"){
          send("AUTHENTICATED",success.data);
        }
        else{
          redirectCancelOperation();
        }
      },
      error =>{
        saveLogs(machine.context,sourceId.authenticate,sdkAuthenticate,error,error?.data?.faceData)
        switch (error.code) {
          case "AP002":
            send("SAVETRIES");
            break;
          case "AP003":
            if(error.type === "error" && !error.data.faceData){
              const bioCode = getInternalCode(error.code, sdkAuthenticate)
              send("SAVETRIESANDOUT",bioCode)
            }else{
              const faceData = error.data.faceData || 'DEFAULT';
              const errorMessage = getErrorModalText(faceData);
              setTitleError(errorMessage.title);
              setMessageError(errorMessage.message);
              setIconType(errorMessage.iconType);
              setButtonBack(errorMessage.buttonBack)
              setSecondaryMessageError(errorMessage.secondaryMessage)
              send("CAMERAERROR");
            }
            break;
          default: {
            const bioCode = getInternalCode(error.code, sdkAuthenticate)
            send("REDIRECTERROR",bioCode)
          }
        }
      },
      handleClose);
  }

  const handleClose = close => {
    if (close.code === 'ACT_HELP') {
      showModalCancel(0);
    }
  };

  const handleTestMode = action => {
    const isDocumentNumberInRange = machine.context.documentNumber >= minDocument && machine.context.documentNumber <= maxDocument;
    const hasAttemptsRemaining = machine.context.attemps < maxAttempts;
    if (isDocumentNumberInRange && hasAttemptsRemaining) {
        machine.context.attemps = machine.context.attemps + 1;
        send("SAVETRIES");
    } else {
        const success = { data: { sessionToken: sessionTokenMock } };
        send(action, success.data);
    }
  };

  /*-------SDK TRANSACCIÓN----------*/

  const transactionSdk = async () => {
    transactions = !testMode ? new TramitesSDK(config.sdk) : null;
    const body = await getTransactionDataBody()
    if (testMode) {
      send("AUTHORIZATIONTRANSACTION");
      return;
    }
    saveSdkAction('initAuthorization');
    transactions.authorization(body,
      _response => {
        saveLogs(machine.context,sourceId.authorization,sdkAuthorization,_response)
        send("AUTHORIZATIONTRANSACTION");
     },
      error => {
        saveLogs(machine.context,sourceId.authorization,sdkAuthorization,error)
        const bioCode = getInternalCode(error.code, sdkAuthorization)
        error = {code: bioCode.code, message: error.message}
        send("REDIRECTERROR", error);
    });
  };

  const getTransactionDataBody = async () => {
    return {
      sessionToken: machine.context.sessionToken,
      transactionId: machine.context.transactionId}
  };

  const saveSdkAction = async action => {
    const result = {code: action}
    saveLogs(machine.context,sourceId.web,codesNoSDK,result)
  };

  /*-------Pantallas de contexto, T&C----------*/

  const begin = () => {
    send("CONTINUE");
  };

  const aceptTaC = () => {
    send("ACEPTTERMS");
  };

  const redirectionHome = () => {
    window.location.replace(redirectionUrlHomeBank);
  };

  const restartFlow = () => {
    send("RETRY");
  };

  const continueFlow = () => {
    setShowModal(true)
    setShowAlertCancel(false)
  };

  const showModalCancel = cancelTypeSet => {
    setCancelType(cancelTypeSet);
    setShowModal(false)
    setShowAlertCancel(true)
  };

  const redirectCancelOperation = () => {
    const error = errorCodes[cancelType] || errorCodes.default;
    saveLogs(machine.context,sourceId.web,errorCodes,{ code: cancelType})
    send("REDIRECTERROR", error);
  };
  /*-------RENDER----------*/

  return (
    <div className="App">
      <header className="App-header">
      <PrincipalContainer>
        <MainContent>
        <LogoBank id="errorAlert" />
          {machine.matches("recaptcha") && (() => { getRecaptchaToken()})()}
          {machine.matches("enrollmentUser") && (() => { enrollmentSdk()})()}
          {machine.matches("authenticateUser") && (() => { authenticateSDK()})()}
          {machine.matches("authorizeTransaction") && (() => { transactionSdk()})()}
          {(isMatchSpinner.some(machine.matches)) && (<Spinner/>)}
          <HandlerError machine={machine} showAlertCancel={showAlertCancel} continueFlow={continueFlow} redirectCancelOperation={redirectCancelOperation} restartFlow={restartFlow} redirectionHome={redirectionHome} titleError={titleError} messageError={messageError} iconType={iconType} buttonBack={buttonBack} secondaryMessage={secondaryMessageError} timeOutRedirect={timeOut}/>
          <HandlerFlow machine={machine} aceptTaC={aceptTaC} begin={begin} cancelOperation={showModalCancel} isShowing={showModal} setRecatpcha={setRecaptchaToken}/>
          <ContainerRecaptcha topM="20px" display="flex">
            <ReCAPTCHA
              size="invisible"
              ref={recaptchaRef}
              sitekey={config.RECAPTCHA_KEY}
            />
          </ContainerRecaptcha>
        </MainContent>
        <Footer top="0px" topMovil="100%" />
      </PrincipalContainer>
      </header>
    </div>
  );
}

